@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wx-alert-message {
  &.v-alert {
    // Default: Displays as a simple unstyled `div` container
    padding: 0;
    background-color: transparent !important;

    &.bordered {
      border: 1px solid var(--color-border-theme);
    }

    ::v-deep .v-alert__content {
      color: var(--color-text-subtle-theme);

      a:not(.v-icon):not(.v-btn) {
        color: var(--color-text-theme);
        text-decoration: underline;
      }
      p {
        word-break: keep-all;
      }
    }
  }

  &--no-query-results {
    &.v-alert {
      ::v-deep .v-alert {
        &__wrapper {
          .v-icon {
            color: var(--color-text-disabled-theme);
          }
        }
      }

      &.error {
        ::v-deep .v-alert {
          &__content {
            color: var(--color-error);
          }
          &__wrapper {
            .v-icon {
              color: var(--color-error);
            }
          }
        }
      }
      &.warning {
        ::v-deep .v-alert {
          &__wrapper {
            .v-icon {
              color: var(--color-warning);
            }
          }
        }
      }
      &.success {
        ::v-deep .v-alert {
          &__wrapper {
            .v-icon {
              color: var(--color-success);
            }
          }
        }
      }
      &.info {
        ::v-deep .v-alert {
          &__wrapper {
            .v-icon {
              color: var(--color-info);
            }
          }
        }
      }
    }
  }
}
