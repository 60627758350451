.tree-container[data-v-02ca9644] {
  overflow-x: auto;
  height: 100%;
}
.tree-container .list-container[data-v-02ca9644] {
  display: flex;
  height: 100%;
}

/*# sourceMappingURL=Finder.vue.map */.list[data-v-26181b62] {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  min-height: 100%;
  border-right: solid 1px #ccc;
  overflow: auto;
  flex-shrink: 0;
}
.list [draggable=true][data-v-26181b62] {
  cursor: move;
  cursor: grab;
}
.last[data-v-26181b62] {
  flex-grow: 1;
}

/*# sourceMappingURL=FinderList.vue.map */.item[data-v-b012b6c0] {
  padding-right: 10px;
  display: flex;
  align-items: center;
  position: relative;
  border: dashed 3px transparent;
}
.item.expanded[data-v-b012b6c0] {
  background-color: #0b79d0;
  color: white;
}
.item.dragged[data-v-b012b6c0] {
  background-color: rgba(11, 121, 208, 0.5);
}
.item.drag-over[data-v-b012b6c0] {
  border-color: #0b79d0;
  background-color: rgba(11, 121, 208, 0.2);
}
.item.has-drag-handle .inner-item[data-v-b012b6c0] {
  padding-left: 0;
}
.item.no-drop[data-v-b012b6c0] {
  color: gray;
}
.item .inner-item[data-v-b012b6c0] {
  flex: 1;
  min-width: 0;
  padding: 10px;
}
.item[data-v-b012b6c0]:focus {
  outline: none;
}
.item .drag-handle[data-v-b012b6c0] {
  fill: #bbb;
  padding: 10px 1px 10px 0;
  width: 15px;
  height: 15px;
  cursor: move;
  cursor: grab;
}

/*# sourceMappingURL=FinderItem.vue.map */.drop-zone[data-v-b2b8c02e] {
  transition: height 0.1s ease;
  height: 0.5em;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.drop-zone.drag-over[data-v-b2b8c02e] {
  opacity: 0.5;
  margin-bottom: 0;
  height: 2em;
  border: dashed 3px #0b79d0;
  background-color: rgba(11, 121, 208, 0.2);
}
.no-drop *[data-v-b2b8c02e] {
  cursor: no-drop;
}

/*# sourceMappingURL=FinderListDropZone.vue.map */.arrow[data-v-6c65f172] {
  display: inline-block;
  border-right: 3px solid black;
  border-bottom: 3px solid black;
  width: 6px;
  height: 6px;
  transform: rotate(-45deg);
}
.arrow.expanded[data-v-6c65f172] {
  border-color: white;
}

/*# sourceMappingURL=FinderItemArrow.vue.map */